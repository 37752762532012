<template>
	<v-row>
		<v-col>
			<v-autocomplete
				:value="value"
				@change="(v) => $emit('input', v)"
				label="Profile Field"
				:items="fields"
				chips
			></v-autocomplete>
		</v-col>
		<v-col>
			<v-autocomplete
				v-model="values"
				@change="(v) => $emit('update', v)"
				multiple
				label="Values"
				:items="options"
				chips
			></v-autocomplete>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "SearchByFieldRow",
	props: {
		value: { type: String },
	},
	data: () => {
		return {
			values: [],
			field: null,
		};
	},
	watch: {
		value: {
			immediate: true,
			handler(value) {
				if (value) {
					this.$store.dispatch("profileFieldValues/fetchByKey", {
						key: "profile_field",
						value,
					});
				}
			},
		},
		fieldValues() {
			const self = this;
			self.fieldValues.forEach((r) => {
				self.$store.dispatch("users/fetchById", r.user);
			});
		},
	},
	computed: {
		fields() {
			let fields = this.$store.state.profileFields.data;
			fields = Object.values(fields);
			return fields.map((f) => ({ text: f.name, value: f.id }));
		},
		fieldValues() {
			return this.$store.getters.getByKey(
				"profileFieldValues",
				"profile_field",
				this.value
			);
		},
		options() {
			let options = this.fieldValues;
			options = options.map((o) => o.value);
			options = options.filter((o, i) => options.indexOf(o) == i);
			return options;
		},
	},
};
</script>
