<template>
	<v-sheet flat>
		<h2>Search By Profile Field Values</h2>
		<v-form v-model="valid">
			<search-by-field-row
				v-for="(filter, i) in filters"
				:key="i"
				v-model="filter.id"
				:options="filter.values"
				@update="v => filter.values = v"
			></search-by-field-row>
			<v-btn @click="addFilter" text>Add Filter</v-btn>
		</v-form>
		<v-data-table :items="rows" :headers="headers">
			<template v-slot:item.btns="{ item }">
				<v-btn icon :href="`/users/${item.id}`" target="_blank"
					><v-icon>mdi-open-in-new</v-icon></v-btn
				>
			</template>
			<template v-slot:footer.prepend>
				<export-button
					:columns="headers"
					:rows="rows"
					name="Paticipants"
				></export-button>
			</template>
		</v-data-table>
	</v-sheet>
</template>

<script>
import ExportButton from "@c/downloads/Export";
import SearchByFieldRow from "@c/profiles/SearchByFieldRow";

export default {
	name: "ProfileSearch",
	props: {
		id: { type: String },
	},
	components: {
		ExportButton,
		SearchByFieldRow
	},
	data: () => {
		return {
			filters: [],
			valid: null,
			headers: [
				{ value: "name", text: "Name" },
				{ value: "email", text: "Email" },
				{ value: "btns", text: "" },
			],
		};
	},
	computed: {
		users() {
			return this.$store.state.users.data;
		},
		rows() {
			const self = this;
			return this.results
				.map((r) => {
					let user = self.users[r];
					if (user) {
						return {
							id: user.id,
							name: user.name,
							email: user.email,
						};
					}
				})
				.filter((defined) => defined);
		},
		values() {
			return Object.values(this.$store.state.profileFieldValues.data)
		},
		results() {
			const self = this;
			let users = self.values.map( r => r.user );
			users = users.filter( (u, i) => users.indexOf(u) == i );
			return users.filter( (u) => {
				let match = true;
				self.filters.forEach( f => {
					if( !self.values.find( v => {
						return v.user == u && v.profile_field == f.id && f.values.includes(v.value);
					})) {
						match = false;
					}
				} )
				return match;
			})
		},
	},
	methods: {
		addFilter(id = null, values = [] ){
			this.filters.push({ id, values });
		}
	},	
	created() {
		if (this.id) {
			this.addFilter( this.id )
			
		}
		this.$store.dispatch("profileFields/fetchAndAdd", {});
	},
};
</script>
